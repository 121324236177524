
import React, { useState } from "react";

import AudioPlayer from "../components/alafasy/Audio";
import AudioPlayerContext from "./audioPlayerContext";

export default function AudioPlayerProvider(props) {
  const [songs, setSongs] = useState([]);
  const [songIndex, setSongIndex] = useState(-1);
  const [author, setAuthor] = useState("");
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);

  const currentSong = () => {
    if (songIndex < 0)
      return null;
    return songs[songIndex];
  }

  let audioContext = {
    songs,
    setSongs,
    songIndex,
    setSongIndex,
    author,
    setAuthor,
    playing,
    setPlaying,
    progress,
    setProgress,
    currentSong,
  }

  return <AudioPlayerContext.Provider value={audioContext} 
  currentSong={songs[songIndex]}
    author={author}
    songCount={songs.length}
    songIndex={songIndex}
    onNext={() => setSongIndex((i) => i + 1)}
    onPrev={() => setSongIndex((i) => i - 1)}
    playing={playing}
    setPlaying={setPlaying}
    setProgress={setProgress}
    >
    {props.children}
    {songIndex > -1 && <AudioPlayer
      currentSong={songs[songIndex]}
      author={author}
      songCount={songs.length}
      songIndex={songIndex}
      onNext={() => setSongIndex((i) => i + 1)}
      onPrev={() => setSongIndex((i) => i - 1)}
      playing={playing}
      setPlaying={setPlaying}
      setProgress={setProgress}
    />}
  </AudioPlayerContext.Provider>
}