exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-reciters-js": () => import("./../../../src/pages/all-reciters.js" /* webpackChunkName: "component---src-pages-all-reciters-js" */),
  "component---src-pages-all-surah-js": () => import("./../../../src/pages/all-surah.js" /* webpackChunkName: "component---src-pages-all-surah-js" */),
  "component---src-pages-all-translations-js": () => import("./../../../src/pages/all-translations.js" /* webpackChunkName: "component---src-pages-all-translations-js" */),
  "component---src-pages-authors-js": () => import("./../../../src/pages/authors.js" /* webpackChunkName: "component---src-pages-authors-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-monthly-archive-js": () => import("./../../../src/pages/monthly-archive.js" /* webpackChunkName: "component---src-pages-monthly-archive-js" */),
  "component---src-pages-newsletter-subscription-js": () => import("./../../../src/pages/NewsletterSubscription.js" /* webpackChunkName: "component---src-pages-newsletter-subscription-js" */),
  "component---src-pages-reciters-js": () => import("./../../../src/pages/reciters.js" /* webpackChunkName: "component---src-pages-reciters-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-yearly-archive-js": () => import("./../../../src/pages/yearly-archive.js" /* webpackChunkName: "component---src-pages-yearly-archive-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-author-reciters-js": () => import("./../../../src/templates/authorReciters.js" /* webpackChunkName: "component---src-templates-author-reciters-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

